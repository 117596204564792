// COLORS
$main: #333333;
$sky: #21D4FD;
$grey: #f1efeb;

$section-w: 750;

@mixin pb() {
	padding-bottom: 200px; }
@mixin pt() {
	padding-top: 160px; }

// FONTS
@font-face {
	font-family: Bodoni;
	src: url("../fonts/Bodoni.woff2") format("woff2"), url("../fonts/Bodoni.woff") format("woff"), url("../fonts/Bodoni.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap; }
@mixin roboto() {
	font-family: 'Roboto', sans-serif; }
