.list {
	max-width: $section-w + 100 + px;
	width: 100%;
	background: #fff;
	margin: 160px auto 0 auto;
	padding: 80px;
	@include r(750) {
		padding: 80px 0px;
		&__title {
			font-size: 45px; } }
	&__title {
		font-size: 55px;
		font-family: Bodoni;
		font-weight: lighter;
		&.pt {
			padding-top: 80px; } }
	&__separator {
			width: 100%;
			height: 2px;
			background: #e5e5e5;
			margin: 40px 0;
			border-radius: 10px; }

	&__ul {
		li {
			position: relative;
			padding-left: 40px;
			line-height: 1.2;
			margin-bottom: 50px;
			svg {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate3d(0, -50%, 0); } } } }
