body {
	color: $main;
	@include roboto;
	background-image: url("../img/dot-pattern.png"); }

// scrollbra
::-webkit-scrollbar {
	width: 7px; }
::-webkit-scrollbar-track {
	background: #fff;
	opacity: 0.5; }
::-webkit-scrollbar-thumb {
	background: $main;
	border-radius: 50px; }
::-webkit-scrollbar-thumb:active {
	background: rgb(100, 100, 100); }



::selection {
	background: $main;
	color: whitesmoke; }

a {
	text-decoration: none;
	color: inherit; }

p, li, a {
	line-height: 1.5;
	font-size: 19px;
	color: rgb(72, 72, 72);
	font-weight: 300; }

.title {
	font-size: 60px;
	font-family: Bodoni;
	font-weight: lighter; }


.blue {
	color: $sky !important; }

.separator {
	width: 50px;
	height: 2px;
	background: $main;
	margin: 40px auto;
	border-radius: 10px;
	&.full {
		background: #e5e5e5;
		width: 100%; } }

.section__wrapper {
	position: relative;
	max-width: $section-w + px;
	width: 100%;
	margin: 0 auto;
	padding: 0 20px; }


.swiper-container {
	width: 600px;
	height: 300px; }


// Description
.description {
	position: absolute;
	bottom: 0;
	left: -1px;
	right: -1px;
	top: 0;
	background: #FFF;
	opacity: 0;
	padding: 80px;
	transition: opacity .5s;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 50;
	&__title {
		font-family: Bodoni;
		font-weight: lighter;
		font-size: 18px;
		transform: translate3d(0, 20px, 0);
		opacity: 0;
		transition: transform .3s .2s ease-in-out, opacity .3s .2s; }
	&__separator {
		width: 20px;
		height: 2px;
		background: rgba($main, .2);
		margin: 26px 0 30px 0;
		transform: translate3d(0, 20px, 0);
		opacity: 0;
		transition: transform .3s .1s ease-in-out, opacity .3s .1s; }
	&__text {
		font-weight: 300;
		font-size: 20px;
		line-height: 1.5;
		transform: translate3d(0, 20px, 0);
		opacity: 0;
		transition: transform .3s 0s ease-in-out, opacity .3s 0s; } }


.loading {
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate3d(0, -50%, 0);
	background: $sky;
	width: 7px;
	height: 7px;
	border-radius: 50%; }


.progress {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate3d(0, -50%, 0);
	width: 16px;
	height: 16px;
	opacity: .4;
	&__cube {
		width: calc(100% * (1/3));
		height: calc(100% * (1/3));
		background: $sky;
		-webkit-backface-visibility: hidden;
		float: left;
		animation: progress 1.3s infinite ease-in-out;
		&:nth-child(1) {
			animation-delay: 0.2s;
			border-radius: 50% 0 0 0; }
		&:nth-child(2) {
			animation-delay: 0.4s; }
		&:nth-child(3) {
			animation-delay: 0.6s;
			border-radius: 0 50% 0 0; }
		&:nth-child(4) {
			animation-delay: 0.1s; }
		&:nth-child(5) {
			animation-delay: 0.3s; }
		&:nth-child(6) {
			animation-delay: 0.5s; }
		&:nth-child(7) {
			animation-delay: 0s;
			border-radius: 0 0 0 50%; }
		&:nth-child(8) {
			animation-delay: 0.2s; }
		&:nth-child(9) {
			animation-delay: 0.4s;
			border-radius: 0 0 50% 0; } } }

@keyframes progress {
	0%, 70%, 100% {
		transform: scale3D(1, 1, 1); }
	35% {
		transform: scale3D(0, 0, 1); } }
