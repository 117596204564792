.header {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	&__background {
		width: 100%;
		height: 100%;
		background-image: url('../img/hero.jpg');
		background-position: center;
		background-size: cover; }


	&__welcome {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-100%, -50%, 0);
		@include r(950) {
			transform: translate3d(-50%, -50%, 0);
			.title {
				font-size: 100px; } }


		.title {
			font-size: 140px;
			line-height: 1.2;
			text-align: left;
			font-family: Bodoni; }

		.sub-title {
			text-align: left;
			position: relative;
			font-weight: light;
			font-size: 16px;
			opacity: 1;
			text-transform: uppercase;
			margin-left: 35px;
			letter-spacing: 2px;
			&:after {
				content: '';
				position: absolute;
				left: -10px;
				top: calc(50% + 1px);
				transform: translate3d(-100%, -50%, 0);
				width: 20px;
				height: 1px;
				background: $main;
				border-radius: 10px; } }
		.button {
			display: inline-block;
			// margin: 40px 0
			padding: 20px;
			color: whitesmoke;
			font-size: 18px;
			cursor: pointer;
			// border-radius: 50px
			// background-repeat: no-repeat
			// background-image: linear-gradient($main, $main),
			// linear-gradient($main, $main),
			// linear-gradient($main, $main),
			// linear-gradient($main, $main),
			// linear-gradient($main, $main),
			// linear-gradient($main, $main),
			// linear-gradient($main, $main),
			// linear-gradient($main, $main)
			// background-size: 1px 10px,
			// 1px 10px,
			// 10px 1px,
			// 10px 1px,
			// 1px 10px,
			// 1px 10px,
			// 10px 1px,
			// 10px 1px
			// background-position: 0 100%,
			// 0 0,
			// 0 0,
			// 100% 0,
			// 100% 0,
			// 100% 100%,
			// 100% 100%,
			// 0 100%
			// transition: background .3s
			// &:hover
			// 	background-size: 1px 50%,
			// 	1px 50%,
			// 	50% 1px,
			// 	50% 1px,
			// 	1px 50%,
			// 	1px 50%,
			// 	50% 1px,
 } }			// 	50% 1px


	.scroll-icon {
		position: absolute;
		bottom: 60px;
		left: 50%;
		transform: translate3d(-50%, 0, 0);
		display: inline-block;
		border: 2px solid $main;
		padding: 17px 10px;
		border-radius: 50px;
		opacity: .7;
		@include rh(750) {
			display: none; }
		&:hover {
			opacity: 1; }
		&:after {
			content: '';
			position: absolute;
			top: 7px;
			left: calc(50% - 0px);
			transform: translate3d(-50%, 0, 0);
			height: 10px;
			width: 2px;
			background: $main;
			border-radius: 10px;
			animation: scrollIcon 2s ease-in-out infinite; } } }

@keyframes scrollIcon {
	to {
		transform: translate3d(-50%, 9px, 0);
		opacity: .5; } }





.navigation {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	background: #fff;
	width: 280px;
	box-shadow: 1px 0px 2px rgba(0, 0, 0, .2);
	z-index: 1000;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 100px 50px 50px 50px;
	transform: translate3d(-100%, 0, 0);
	transition: transform .5s cubic-bezier(0.39, 0.575, 0.565, 1);
	@include rh(370) {
		padding: 20px 50px; }
	@include rh(550) {
		padding: 50px 50px 0px 50px; }
	@include rh(750) {
		padding: 50px; }
	@include r(800) {
		width: 240px; }


	// begin open animation
	&.show-navigation {
		transform: translate3d(0, 0, 0);

		.menu__logo {
			opacity: 0; }

		.menu__burger {
			span {
				&:first-child {
					transform: rotate(45deg) translate3d(0, 10px, 0); }
				&:nth-child(2) {
					transform: translate3d(20px, 0, 0);
					opacity: 0; }
				&:last-child {
					transform: rotate(-45deg) translate3d(0, -10px, 0); } } }

		.navigation__nav__link {
			opacity: 1; } }






	// end open animation
	&__header {
		font-size: 1.5em;
		font-weight: bold;
		padding-bottom: 50px;
		@include rh(370) {
			padding-bottom: 30px; }
		>span {} }

	&__nav {
		flex: 1;
		display: flex;
		flex-direction: column;

		&__link {
			line-height: 2.5;
			opacity: 0;
			transition: opacity .5s;
			&:hover {
				color: $sky; }

			$links: 6;
			@for $i from 1 to $links {
				&:nth-child(#{$i}) {
					transition-delay: $i / 10 + 0.1 + s; } } } }

	&__footer {
		text-align: center;
		@include rh(400) {
			display: none; }
		&__social {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			svg {
				width: 80px;
				height: 60px;
				opacity: .5;
				transition: opacity .3s;
				cursor: pointer;
				&:hover {
					opacity: 1; } } }
		&__credits {
			font-weight: light;
			font-size: 13px;
			opacity: .2;
			color: black;
			@include rh(550) {
				display: none; } } }




	.menu {
		position: absolute;
		top: 30px;
		right: 0;
		height: 60px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding: 0 40px;
		// margin: 30px
		transform: translate3d(100%, 0, 0);
		@include rh(650) {
			top: 0;
			padding: 0 20px;
			background: #FFF;
			width: 100vw;
			box-shadow: inset 1px 0px 2px rgba(0, 0, 0, .2); }


		&__logo {
			display: block;
			margin-left: 20px;
			font-size: 18px;
			font-weight: medium;
			transition: opacity .5s;
			>span {
				font-weight: bold; } }

		&__burger {
			position: relative;
			width: 25px;
			height: 30px;
			cursor: pointer;
			span {
				position: absolute;
				left: 0;
				top: 50%;
				display: block;
				border-radius: 10px;
				width: 100%;
				height: 2px;
				background: $main;
				transition: transform .4s ease-in-out, opacity .4s ease-in-out;
				&:first-child {
					top: calc(50% - 7px); }
				&:last-child {
					top: calc(50% + 7px); } }
			&:hover {} } } }


.footer {
	text-align: center;
	width: 100%;
	padding: 30px;
	background: #1e1e1e;
	margin-top: -30px;
	background-image: url(../img/footer.jpg);
	background-size: cover;
	background-position: center;
	>span {
		color: #FFF;
		font-family: Bodoni;
		font-weight: lighter;
		font-size: 25px; }
	>p {
		display: inline-block;
		margin: 0 auto;
		line-height: 1;
		font-size: 16px;
		max-width: 400px;
		color: rgba(#FFF, .8); }
	.separator {
		margin: 15px auto;
		background: rgba(#FFF, .5);
		height: 1px;
		width: 20px; } }
