.mission {
	position: relative;
	max-width: $section-w + px;
	margin: 0 auto;
	@include pb;


	&__photo, &__text {
		background: #FFF; }

	&__photo {
		max-width: 550px;
		width: 100%;
		height: 550px;
		transform: translate3d(-25%, 0, 0);
		background-image: url('../img/mission-bg.jpg');
		background-size: cover;
		background-position: center;
		@include r(1100) {
			transform: translate3d(0, 0, 0);
			margin: 0 auto;
			max-width: 750px;
			height: 350px;
			background-position: center top; } }
	&__data {
		position: absolute;
		top: 0;
		left: 0;
		background: #FFF;
		max-width: 600px;
		width: 100%;
		padding: 80px;
		transform: translate3d(50%, 30%, 0);
		@include r(1100) {
			position: relative;
			display: block;
			top: auto;
			left: auto;
			transform: translate3d(0, 0, 0);
			margin: 0 auto;
			max-width: 750px; }
		@include r(635) {
			padding: 80px 20px; }



		>.title {
			font-size: 55px; }

		&__separator {
			width: 100%;
			height: 2px;
			background: #e5e5e5;
			margin: 40px 0;
			border-radius: 10px; }

		>p {
			color: rgb(102, 102, 102); } } }
