.contacts {
	position: relative;
	max-width: $section-w + 100 + px;
	width: 100%;
	margin: 160px auto;

	&__photo {
		width: 100%;
		height: 350px;
		background: $main;
		background-image: url(../img/contacts.jpg);
		background-size: cover;
		background-position: center bottom; }
	&__data {
		width: 80%;
		background: white;
		min-height: 260px;
		margin: 0 auto;
		transform: translate(0, -150px);
		background: white;
		padding: 80px;
		@include r(750) {
			width: 100%;
			transform: translate(0, 0px); }
		@include r(650) {
			padding: 80px 20px; }
		&__title {
			font-size: 60px;
			font-family: Bodoni;
			font-weight: lighter; }

		&__links {
			display: flex;
			flex-direction: column;
			a, span {
				font-size: 19px;
				color: rgb(72, 72, 72);
				font-weight: 300;
				display: block;
				position: relative;
				line-height: 1.2;
				margin-bottom: 40px;
				padding-left: 40px;
				&:hover {
					color: $sky; }
				svg {
					content: '';
					position: absolute;
					left: 0;
					top: 50%;
					transform: translate(0, -50%);
					width: 20px;
					height: 20px; } }
			span {
				&:hover {
					color: rgb(72, 72, 72); } } } } }


