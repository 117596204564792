.icon-done {
  width: 1em;
  height: 1em;
  fill: #21D4FD;
}
.icon-facebook {
  width: 1em;
  height: 1em;
  fill: #333;
}
.icon-linkedin {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-location {
  width: 1em;
  height: 1em;
  fill: #21D4FD;
}
.icon-mail {
  width: 1em;
  height: 1em;
  fill: #21D4FD;
}
.icon-phone {
  width: 1em;
  height: 1em;
  fill: #21D4FD;
}
.icon-user {
  width: 1em;
  height: 1em;
  fill: #21D4FD;
}
