.gallery {
	width: 100% !important;
	height: 450px !important;
	position: relative;
	background: $main;
	@include rh(570) {
		height: 350px !important; }
	@include rh(670) {
		height: 450px !important; }


	&__slide {
		position: relative;
		box-sizing: border-box;
		border-right: 1px solid $main;
		&:hover {
			>img {
 }				// transform: scale3d(1,1,1)

			.description {
				opacity: .95;
				&__title, &__separator, &__text {
					transform: translate3d(0, 0, 0);
					opacity: 1; } } }

		>img {
			// transform: scale3d(1.05,1.05,1.05)
			height: 100%;
			transition: transform .3s ease-in-out; } } }



.swiper-container {
	width: 100%;
	height: 100%; }
.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center; }
